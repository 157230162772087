// 포트폴리오 요약
// 한줄로 표현하는 나, 나에 대한 3줄요약 등을 넣을 예정
import React from "react";
import "./Summary.css";

function Summary() {
  return (
    <>
      <h1 className="grand-title">쓸만한 팔로워! 곽종환입니다</h1>
      
      <div className="profile-area">
        <div className="little-title-wrap">
          <p className="little-title">프로필</p>
          <p className="little-title-en">Profile</p>
        </div>
        <div className="profile-group">
          <img className="profile-picture" src="./profile-kjh.jpg" alt="프로필 사진" />
          <div>
            <div className="myname-wrap">
              <div className="myname">곽종환</div>
              <div className="myname-en">Jonghwan Kwak</div>
            </div>
            <div className="profile-address">
              <div>Front-End Engineer</div>
              <div>Python, Javascript</div>
            </div>
            <div className="profile-address-link">Github: <a href="https://github.com/bmyusharp">https://github.com/bmyusharp</a></div>
            <div className="profile-address-link">Twitter: <a href="https://twitter.com/Bermyu_">https://twitter.com/Bermyu_</a></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Summary;