// 간략한 경험내역(싸피 등)
// 간략한 연락처
// 간략한 프로젝트 리스트
import React from "react";
import "./Detail.css";

function Detail() {
  return (
    <>
      <div className="profile-area">
        <div className="little-title-wrap">
          <p className="little-title">경험</p>
          <p className="little-title-en">Experience</p>
        </div>

        <div className="experience-wrap">
          <img className="ssafy-logo" src="./ssafy-logo.png" alt="SSAFY 로고" onClick={() => window.open("https://www.ssafy.com/", "_blank")}/>
          <div className="ssafy-subtitle">
            <div className="ssafy-title">삼성 청년 SW 아카데미 (SSAFY)</div>
            <div>2022.01 ~ 2022.12</div>
            <div>7기 Python</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Detail;