// 프로젝트들을 목록으로 보여줄 예정
// 프로젝트 선택 시 자세한 부분 열람 가능하게 하는 것도 고려중
import React from "react";
import "./Projects.css";

import Nangman from "./Nangman.jsx";
import Sudal from "./Sudal.jsx";
import Jaws from "./Jaws.jsx";

function Projects() {
  return (
    <>
      <div className="profile-area">
        <div className="little-title-wrap">
          <p className="little-title">프로젝트</p>
          <p className="little-title-en">Project</p>
        </div>
        <div>
          <Jaws />
          {/* <Sudal /> */}
          <Nangman />
        </div>
      </div>
    </>
  )
}

export default Projects;