// 메이플하면서 바로바로 찾아가기 좋은 사이트들 전부 링크 모아놓은 페이지

import React from 'react';
import './Maple.css';
import memberList from '../data/memberList.json';

function Maple() {
  const memberLink = []
  memberList.map((member, index) => {
    memberLink.push(<p><a href={"https://maple.gg/u/" + member.characterName} target="_black" rel="noopener noreferrer">{member.name} - {member.characterName}</a></p>)
  })

  return (
    <>
      <div className="major-page">
        <a className="maple-official" href="https://maplestory.nexon.com/" target="_blank" rel="noopener noreferrer">메이플스토리 홈페이지</a>
        <a className="maple-gg" href="https://maple.gg/" target="_blank" rel="noopener noreferrer">캐릭터 정보 - Maple.gg</a>
        <a className="maple-inven" href="https://maple.inven.co.kr/" target="_blank" rel="noopener noreferrer">메이플 인벤</a>
        <a className="maple-gallery" href="https://gall.dcinside.com/board/lists/?id=maplestory_new" target="_blank" rel="noopener noreferrer">메이플 갤러리</a>
      </div>

      <div className="minor-tip-wrap">
        <div className="minor-wrap">
          <h1 className="minor-page-title">메이플 소모임</h1>
          <div className="minor-page">
            {/* <a href="https://www.when2meet.com/?18908851-cZEfT" target="_blank" rel="noopener noreferrer">(중요) 2월 4주차 시간표(2/23~3/1)</a> */}
            <h2 className="bm-try-title">선택받은 세렌 트라이</h2>
            <div className="bm-try">
              <p><a href="https://bermyu.notion.site/468eabdd659f4246a85aeae325f04d3a" target="_blank" rel="noopener noreferrer">노션 페이지</a></p>
              <p><a href="https://bermyu.notion.site/92142a9a50e944a8b845af76e6a4bfb2?v=04841f2e7d714100b9c6b36a3d3381a8" target="_blank" rel="noopener noreferrer">트라이 영상 기록 페이지</a></p>
            </div>
            <h2 className="bm-try-title">검은마법사 트라이 과정</h2>
            <div className="bm-try">
              <p><a href="https://bermyu.notion.site/a6af4edca07a46ee899928cb2e6a1e4c" target="_blank" rel="noopener noreferrer">노션 페이지</a></p>
              <p><a href="https://bermyu.notion.site/4df363860497450ba519ccc49e97755c?v=43a8fe6134d24003b5ef0f75f2ffb23f" target="_blank" rel="noopener noreferrer">트라이 영상 기록 페이지</a></p>
            </div>
            <h2 className="member-title">멤버 닉네임 모음</h2>
            <div className="member">
              {memberLink}
            </div>
          </div>
        </div>

        <div>
          <h1 className="hisyo-link" onClick={() => window.open("https://bermyu.com/@hisyo", "_blank")}>일일퀘스트 체크리스트</h1>
          <h1 className="tip-page-title">편의 팁 링크모음</h1>
          <div className="tip-page">
            <p><a href="https://in-fo.github.io/midnight-chaser-helper/" target="_blank" rel="noopener noreferrer">미드나잇 기록지</a></p>
            <p><a href="https://blog.naver.com/PostView.naver?blogId=rnjsdyd2009&logNo=222482705336" target="_blank" rel="noopener noreferrer">프로텍트 에스페라 가이드</a></p>
            <p><a href="https://xenogents.github.io/LegionSolver/" target="_blank" rel="noopener noreferrer">유니온 점령 서포트</a></p>
            <p><a href="https://mesu.live/sim/starforce" target="_blank" rel="noopener noreferrer">스타포스 시뮬레이터</a></p>
            <p><a href="https://mapleutils.com/seed/48" target="_blank" rel="noopener noreferrer">시드 48층</a></p>
            <p><a href="https://meso.kr/" target="_blank" rel="noopener noreferrer">몬스터라이프 몬스터 보유농장 검색</a></p>
            <p><a href="http://wachan.me/farm.php" target="_blank" rel="noopener noreferrer">몬스터라이프 조합식 찾기</a></p>
            <p><a href="https://www.inven.co.kr/board/maple/2304/24349" target="_blank" rel="noopener noreferrer">유니온 육성 사냥터</a></p>
            <p><a href="https://drive.google.com/drive/folders/1kJHbFVCouYyAU8IuWkVvc7q2jEPv-Q0o?usp=sharing" target="_blank" rel="noopener noreferrer">보스분배금 정산 시트</a></p>
            <p><a href="https://maple.gazua.in/starforce" target="_blank" rel="noopener noreferrer">스타포스 시뮬레이터2</a></p>
            <p><a href="https://icepeng.github.io/maple-calc/grinding" target="_blank" rel="noopener noreferrer">사냥터 찾기</a></p>
          </div>
          <img className="maple-page-background" src="./maple-background.png" alt="페이지 배경" />
        </div>
      </div>
    </>
  )
}

export default Maple;