import Summary from "../components/Summary";
import Detail from "../components/Detail";
import Projects from "../components/Projects";
import Contect from "../components/Contect";

function Main() {
  return (
    <>
      <Summary />
      <Detail />
      <Projects />
      <Contect />
    </>
  )
}

export default Main;