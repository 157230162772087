// 깃헙, 트위터, 그 외 기타 정보들을 적을 예정
import React from "react";
import "./Contect.css";

function Contect() {
  return (
    <>
    </>
  )
}

export default Contect;