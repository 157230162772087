import React from "react";

function NotFound() {
  return (
    <>
      <h1>404 Not Found</h1>
      <p>정확한 주소를 통한 접근 부탁드립니다.</p>
    </>
  );
}

export default NotFound;