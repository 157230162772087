// 곽종환을 소개하는 페이지가 아닌, 버뮤#에 대한 소개를 하는 페이지

import React, { useState } from "react";
import "./Bermyu.css";

function Bermyu() {

  // 칭호 관련 이미지 경로
  // 3개의 이미지를 전부 로드하고 중지시켜둠, 보이는 이미지는 기본 이미지
  // 마우스 오버 시에 오버 이미지 1회 보여주고 재생 후 정지
  // 마우스 아웃 시에 아웃 이미지 1회 보여주고 재생 후 정지
  const [pzgs, setPzgs] = useState("./named-assets/pzgs-normal.gif");
  const [random, setRandom] = useState(0);
  const [ssafy, setSsafy] = useState("./named-assets/ssafy-normal.png");
  const [son, setSon] = useState("./named-assets/son-normal.gif");

  return (
    <>
      <h1 className="grand-title">안녕하세요! 버뮤# 입니다</h1>

      <div className="profile-area">
        <div className="little-title-wrap">
          <p className="little-title">프로필</p>
          <p className="little-title-en">Profile</p>
        </div>
        <div className="profile-group">
          <img className="profile-picture" src="./profile.png" alt="프로필 사진" />
          <div>
            <div className="nickname">버뮤#</div>
            <div className="profile-address">
              <div>메인 계정  <a href="https://twitter.com/Jogakusei_Akuma">@Jogakusei_Akuma</a></div>
              <div>개발 계정  <a href="https://twitter.com/Bermyu_">@Bermyu_</a></div>
            </div>
          </div>
        </div>
      </div>

      <div className="designation-area">
        <div className="little-title-wrap">
          <p className="little-title">칭호</p>
          <p className="little-title-en">Named</p>
        </div>
        <div className="designation">
          <img className="pzgs-designation"
            src={pzgs}
            onMouseOver={() => {
              setRandom(Math.floor(Math.random() * 2) + 1)
              setPzgs(`./named-assets/pzgs-hover${random}.gif`)
            }}
            onMouseOut={() => setPzgs(`./named-assets/pzgs-out${random}.gif`)}
            onClick={() => window.open("https://cafe.naver.com/pzgs", "_blank")}
            alt="펜지꼬스 칭호"
          />
          <img className="ssafy-designation" 
            src={ssafy}
            onMouseOver={() => setSsafy("./named-assets/ssafy-hover.png")}
            onMouseOut={() => setSsafy("./named-assets/ssafy-normal.png")}
            onClick={() => window.open("https://www.ssafy.com/", "_blank")}
            alt="싸피7기 칭호"
          />
          <img className="son-designation"
            src={son}
            onMouseOver={() => setSon("./named-assets/son-hover.gif")}
            onMouseOut={() => setSon("./named-assets/son-normal.gif")}
            alt="아들 칭호"
          />
        </div>
      </div>

      <div className="active-area">
        <div className="little-title-wrap">
          <p className="little-title">게임</p>
          <p className="little-title-en">Game</p>
        </div>
        <div className="maple-wrap">
          <div className="maple-game-wrap" onClick={() => window.open("https://maplestory.nexon.com/", "_blank")}>
            <img className="maple" src="./maple.png" alt="메이플스토리" />
            <p className="game-title">메이플스토리</p>
          </div>
          <div className="maple-status-wrap" onClick={() => window.open("https://maple.gg/u/최강버프", "_blank")}>
            <img className="maple-server" src="./inosis.png" alt="이노시스" />
            <p className="game-nickname">최강버프</p>
            <img className="maple-character" src="./maple-character-342.png" alt="최강버프" />
          </div>
        </div>
        <div className="mahjongsoul-wrap">
          <div className="mahjongsoul-game-wrap" onClick={() => window.open("https://game.mahjongsoul.com/index.html", "_blank")}>
            <img className="mahjongsoul" src="./mahjongsoul.png" alt="작혼" />
            <p className="game-title">작혼</p>
          </div>
          <div className="mahjongsoul-rank-wrap" onClick={() => window.open("https://amae-koromo.sapk.ch/player/75224613/9", "_blank")}>
            <img className="mahjongsoul-rank" src="./jakho.png" alt="작호" />
            <p className="game-nickname">BermyuSHARP</p>
            <img className="mahjongsoul-character" src="./toki-34.png" alt="토키" />
          </div>
        </div>
      </div>

      <div className="bermyu-footer">
        <p>이 페이지는 <a href="https://shiftpsh.com/">shiftpsh.com</a>에서 영감을 받아 만들어졌습니다.</p>
      </div>
    </>
  )
}

export default Bermyu;