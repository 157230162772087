import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Main from "./pages/Main";
import Bermyu from "./pages/Bermyu";
import Maple from "./pages/Maple";
import Hisyo from "./pages/Hisyo";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/@kwakjonghwan" element={<Main />} />
          <Route path="/@bermyu" element={<Bermyu />} />
          <Route path="/@maple" element={<Maple />} />
          <Route path="/@hisyo" element={<Hisyo />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
