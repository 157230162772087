import React, { useRef, useState, useEffect } from "react";
import "./Nangman.css";
import project from "../data/projectData.json";

function Nangman() {

  const contentRef = useRef();
  const [contentHeight, setContentHeight] = useState(0);
  // const [isTouched, setIsTouched] = useState(false);
  // const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    setContentHeight(contentRef?.current?.clientHeight ?? 0);
  }, []);

  return (
    <div className="project-all-wrap">
          <div className="project-thumbnail-wrap">
            <img className="nangman-icon" src="./nangman-icon.png" alt="낭만버스 로고" />
            <div>
              <div className="title-icon-wrap">
                <div className="project-title">낭만버스</div>
                <img className="vue-icon" src="./vue-icon.png" alt="Vue.js 로고" />
                <img className="spring-boot-icon" src="./spring-boot-icon.png" alt="Spring Boot 로고"/>
                <img className="redis-icon" src="./redis-icon.png" alt="Redis 로고"/>
              </div>
              <div>출근길 버스에 낭만을 찾아드립니다.</div>
              <div>위치서비스 기반 익명 채팅 서비스</div>
            </div>
          </div>

        <div className="nangman-summary-wrap">
          {/** 이 부분부터는 아이콘 클릭으로 열고 닫을 것 */}
          <div className="nangman-summery" ref={contentRef}>
            <div className="project-detail-wrap">
              <div className="project-detail-title">개요</div>
              <div className="project-detail-content">{project.nangman.summary}</div>
            </div>
            <div className="project-detail-wrap">
              <div className="project-detail-title">기간</div>
              <div className="project-detail-content">{project.nangman.period}</div>
            </div>
            <div className="project-detail-wrap">
              <div className="project-detail-title">링크</div>
              <div className="project-detail-link"
                onClick={() => window.open(project.nangman.repository, "_blank")}
              >{project.nangman.repository}</div>
            </div>
            <div className="project-detail-wrap">
              <div className="project-detail-title">기술</div>
              <div className="project-detail-content">{project.nangman.techStack}</div>
            </div>
            <div className="project-detail-wrap">
              <div className="project-detail-title">담당</div>
              <div className="project-detail-content">
                <div>{project.nangman.responsibility}</div>
                <div>{project.nangman.responsibility2}</div>
              </div>
            </div>
            <div className="project-detail-wrap">
              <div className="project-detail-title">UCC</div>
              <div className="project-detail-content">
                <div className="project-ucc-wraper">
                  <div className="project-detail-link"
                    onClick={() => window.open(project.nangman.uccLink1, "_blank")}
                  >{project.nangman.uccLink1}</div>
                  <div className="project-ucc-time">(1분 8초)</div>
                </div>
                <div className="project-ucc-wraper">
                  <div className="project-detail-link"
                    onClick={() => window.open(project.nangman.uccLink2, "_blank")}
                  >{project.nangman.uccLink2}</div>
                  <div className="project-ucc-time">(1분 8초)</div>
                </div>
              </div>
            </div>
            <div className="project-detail-wrap">
              <div className="project-detail-title">UI 예시</div>
              <div className="project-detail-content">
                <div className="project-ui-all-wrap">
                  <div className="project-ui-wrap">
                    <img className="nangman-ui-examples" src="./ui-example/nangman-onboarding.gif" alt="온보딩 UI" />
                    <div>온보딩 페이지</div>
                    <div>animation CSS가 적용되었습니다.</div>
                  </div>
                  <div className="project-ui-wrap">
                    <img className="nangman-ui-examples" src="./ui-example/nangman-main.gif" alt="메인페이지" />
                    <div>메인 페이지</div>
                    <div>매일 랜덤하게 닉네임을 받으며, 다른 버스들의 정보를 보여줍니다.</div>
                  </div>
                  <div className="project-ui-wrap">
                    <img className="nangman-ui-examples" src="./ui-example/nangman-chattingList.gif" alt="채팅 리스트" />
                    <div>채팅 목록</div>
                    <div>유저의 위치 근처를 지나는 버스 목록(채팅방)을 보여줍니다.</div>
                  </div>
                  <div className="project-ui-wrap">
                    <img className="nangman-ui-examples" src="./ui-example/nangman-chatting.gif" alt="채팅 화면" />
                    <div>채팅</div>
                    <div>상단에 현재 정류장 현황을 안내해주고, 채팅을 주고받을 수 있습니다.</div>
                  </div>
                  <div className="project-ui-wrap">
                    <img className="nangman-ui-examples" src="./ui-example/nangman-posting.gif" alt="방명록" />
                    <div>방명록</div>
                    <div>현재 탑승중인 버스에 영구적으로 남는 쪽지입니다. 언젠가 다시 볼 날을 기대하며 적어보게 됩니다.</div>
                  </div>
                  <div className="project-ui-wrap">
                    <img className="nangman-ui-examples" src="./ui-example/nangman-report.gif" alt="낭만보고서" />
                    <div>낭만보고서</div>
                    <div>낭만버스를 이용한 기록의 데이터들을 정리해서 돌려줍니다. 주목받은 대화 등을 남길 수 있습니다.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* <style jsx>{`
        .nangman-summary-wrap {
          overflow: hidden;
        }
        .nangman-summary {
          transition: 3s ease;
          margin-top: -${contentHeight}px;
          opacity: 0;
        }
        details[open] .nangman-summary {
          margin-top: 0px;
          opacity: 1;
        }
      `}</style> */}
    </div>
  )
}

export default Nangman;