import React from "react";
import "./Jaws.css";
import project from "../data/projectData.json";

function Jaws() {
  return (
    <div className="project-all-wrap">
        <div className="project-thumbnail-wrap">
          <img className="jaws-icon" src="./jaws-icon.png" alt="너는어때 로고" />
          <div>
            <div className="title-icon-wrap">
              <div className="project-title">너는어때</div>
              <img className="react-icon" src="./react-icon.png" alt="React.js 로고" />
              <img className="spring-boot-icon" src="./spring-boot-icon.png" alt="Spring Boot 로고"/>
            </div>
            <div>우리동네 투표 소통 플랫폼</div>
            {/* <div>위치서비스 기반 익명 채팅 서비스</div> */}
          </div>
        </div>
        <div>
          {/** 이 부분부터는 아이콘 클릭으로 열고 닫을 것 */}
          <div className="jaws-summery">
            <div className="project-detail-wrap">
              <div className="project-detail-title">개요</div>
              <div className="project-detail-content">{project.jaws.summary}</div>
            </div>
            <div className="project-detail-wrap">
              <div className="project-detail-title">기간</div>
              <div className="project-detail-content">{project.jaws.period}</div>
            </div>
            <div className="project-detail-wrap">
              <div className="project-detail-title">링크</div>
              <div className="project-detail-link"
                onClick={() => window.open(project.jaws.repository, "_blank")}
              >{project.jaws.repository}</div>
            </div>
            <div className="project-detail-wrap">
              <div className="project-detail-title">기술</div>
              <div className="project-detail-content">{project.jaws.techStack}</div>
            </div>
            <div className="project-detail-wrap">
              <div className="project-detail-title">담당</div>
              <div className="project-detail-content">
                <div>{project.jaws.responsibility}</div>
                <div>{project.jaws.responsibility2}</div>
              </div>
            </div>
            <div className="project-detail-wrap">
              <div className="project-detail-title">UI 예시</div>
              <div className="project-detail-content">
                <div className="project-ui-all-wrap">
                  <div className="project-ui-wrap">
                    <img className="jaws-ui-examples" src="./ui-example/jaws-locationSetting.gif" alt="동네 설정" />
                    <div>동네 설정</div>
                    <div>설정한 위치 주변의 투표글들을 보고 참여할 수 있습니다.</div>
                  </div>
                  <div className="project-ui-wrap">
                    <img className="jaws-ui-examples" src="./ui-example/jaws-create.gif" alt="투표 생성" />
                    <div>투표 생성</div>
                    <div>게시물을 작성하듯이 투표글을 작성할 수 있습니다.</div>
                  </div>
                  <div className="project-ui-wrap">
                    <img className="jaws-ui-examples" src="./ui-example/jaws-vote.gif" alt="투표하기" />
                    <div>투표</div>
                    <div>투표에 참여하여 의사를 전달할 수 있습니다.</div>
                  </div>
                  <div className="project-ui-wrap">
                    <img className="jaws-ui-examples" src="./ui-example/jaws-statistics.gif" alt="결과분석" />
                    <div>결과분석</div>
                    <div>투표결과를 그래프 형식으로 확인할 수 있습니다.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Jaws;