// 일일퀘스트 비서 기능을 이관한 페이지임
// SPA로, 마우스오버와 마우스 클릭, 스크롤 등에 반응하는 UI를 충실하게 구현할 것

import React from "react";
import "./Hisyo.css";
import Quests from "../components/Quests";

function Hisyo() {
  return (
    <>
      일일퀘스트 비서
      <Quests number="1" />
    </>
  )  
}

export default Hisyo;