import React from "react";
import "./Quests.css";
import quest from "../data/quest.json";

function Quests(props) {
  let number = props.number;
  console.log(quest[number])
  return (
    <>
      <div className="quest-wrap">
        <div className="quest-left-wrap">
          <div className="quest-type">{quest[number].questType.substring(0, 2)}</div>
          <div className="quest-number">{number}</div>
        </div>
        <div className="quest-right-wrap">
          <div className="quest-title">{quest[number].questLowClass}</div>
          <div className="quest-content">{quest[number].questContent}</div>
          {/* <div>{quest[number].questDetail}</div> */} {/* 모라스 UI 생각한 뒤에 결정 */}
        </div>
        <div></div>
      </div>
    </>
  )
}

export default Quests;